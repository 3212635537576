import * as React from 'react'

function href(h) {
  return h.startsWith('/') ? h : `/${h}`
}

export function Link(props) {
  const additionalProps = {}

  if (props.blank) {
    additionalProps.target = '_blank'
  }

  return <a href={props.href} {...additionalProps}>{props.children}</a>
}

export function WebsiteLink({children, ...props}) {
  const additionalProps = {
    href: `${process.env.GATSBY_LS_WEBSITE_URL}${href(props.href)}`
  }

  return <Link {...props} {...additionalProps}>{children}</Link>
}
