// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-getting-started-changelog-mdx": () => import("./../src/getting-started/changelog.mdx" /* webpackChunkName: "component---src-getting-started-changelog-mdx" */),
  "component---src-getting-started-index-mdx": () => import("./../src/getting-started/index.mdx" /* webpackChunkName: "component---src-getting-started-index-mdx" */),
  "component---src-javascript-api-conditions-mdx": () => import("./../src/javascript-api/conditions.mdx" /* webpackChunkName: "component---src-javascript-api-conditions-mdx" */),
  "component---src-javascript-api-configuration-mdx": () => import("./../src/javascript-api/configuration.mdx" /* webpackChunkName: "component---src-javascript-api-configuration-mdx" */),
  "component---src-javascript-api-introduction-mdx": () => import("./../src/javascript-api/introduction.mdx" /* webpackChunkName: "component---src-javascript-api-introduction-mdx" */),
  "component---src-javascript-api-methods-mdx": () => import("./../src/javascript-api/methods.mdx" /* webpackChunkName: "component---src-javascript-api-methods-mdx" */),
  "component---src-javascript-api-platforms-mdx": () => import("./../src/javascript-api/platforms.mdx" /* webpackChunkName: "component---src-javascript-api-platforms-mdx" */),
  "component---src-javascript-api-rate-limits-mdx": () => import("./../src/javascript-api/rate-limits.mdx" /* webpackChunkName: "component---src-javascript-api-rate-limits-mdx" */),
  "component---src-javascript-api-tutorials-mdx": () => import("./../src/javascript-api/tutorials.mdx" /* webpackChunkName: "component---src-javascript-api-tutorials-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-rest-api-introduction-mdx": () => import("./../src/rest-api/introduction.mdx" /* webpackChunkName: "component---src-rest-api-introduction-mdx" */),
  "component---src-rest-api-reference-mdx": () => import("./../src/rest-api/reference.mdx" /* webpackChunkName: "component---src-rest-api-reference-mdx" */),
  "component---src-webhooks-event-types-mdx": () => import("./../src/webhooks/event-types.mdx" /* webpackChunkName: "component---src-webhooks-event-types-mdx" */),
  "component---src-webhooks-introduction-mdx": () => import("./../src/webhooks/introduction.mdx" /* webpackChunkName: "component---src-webhooks-introduction-mdx" */)
}

